<template>
  <Box>
    
    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Inspections
        </div>
      </Column>
    </Row>

    <LoadingRow v-if="isLoadingMonthly"/>
    
    <Row v-if="isLoadingMonthly">
     This page takes a long time to load, it's loading all stats for all ACTIVE companies
    </Row>
    <Row v-else>
     <Column :width="15">
     <Box>
      <Row>
       <Column :width="1"><Button v-on:click="toggle('paying')">Paying</Button></Column>
       <Column :width="1"><Button v-on:click="toggle('notPaying')">Not Paying</Button></Column>
       <Column :width="13"></Column>
      </Row>
      <TitleRowV2 :subtitle="true"> Last 15 Months </TitleRowV2>
      
      <!-- <Row>
       <Column :width="2"></Column>
       <Column :width="13">
        <Box>
         <Row>
          <Column :width="1"></Column>
          <Column :width="14">
           <Box>
            <Row>
             <Column :width="1" v-for="(month, index) in previous15Months()" v-bind:key="month + index" :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'"
             class=" rc-text-value rc-text-centre">{{ month }}</Column>
            </Row>
           </Box>
          </Column>
         </Row>
        </Box>
       </Column>
      </Row> -->
      <Row v-for="(company, index) in currentList" v-bind:key="company.id()" :showBorder="true" :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'">
        <Column :width="2">
          {{index + 1}}) {{ company.name() }}
          ({{ company.address().find().province() }})
        </Column>
        <Column :width="13" >
            <div :id="company.fileSystemId()" :ref="company.fileSystemId()"></div>
        </Column>
      </Row>
      <!-- <Row v-for="(company, index) in currentList" v-bind:key="company.id()" :showBorder="true" :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'">
       <Column :width="2">
        {{index + 1}}) {{ company.name() }}
        ({{ company.address().find().province() }})
       </Column>
       <Column :width="13" >
        <Box>
         <Row>
          <div class="rc-box-col-1 rc-text-label rc-text-right">Inspections</div>
          <div class="rc-box-col-14">
              <Box>
                <Row>
                  <div v-for="(item, index) in statsForCompany(company)" 
                      v-bind:key="item.id()" 
                      :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                      class=" rc-text-value rc-text-centre"> {{ item.get("diff") }} {{ item.get("health") }} {{ item.inspections() }} 
                  </div>
                </Row>
              </Box>
          </div>
         </Row>
         <Row>
          <div class="rc-box-col-1 rc-text-label rc-text-right">Hours</div>
          <div class="rc-box-col-14">
              <Box>
                <Row>
                  <div v-for="(item, index) in statsForCompany(company)" 
                      v-bind:key="item.id()" 
                      :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                      class=" rc-text-value rc-text-centre"> {{ item.timesheets() }} 
                  </div>
                </Row>
              </Box>
          </div>
         </Row>
         <Row>
          <div class="rc-box-col-1 rc-text-label rc-text-right">Vehicles</div>
          <div class="rc-box-col-14">
              <Box>
                <Row>
                  <div v-for="(item, index) in statsForCompany(company)" 
                      v-bind:key="item.id()" 
                      :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                      class=" rc-text-value rc-text-centre"> {{ item.vehicles() }} 
                  </div>
                </Row>
              </Box>
          </div>
         </Row>
         <Row>
          <div class="rc-box-col-1 rc-text-label rc-text-right">Employees</div>
          <div class="rc-box-col-14">
              <Box>
                <Row>
                  <div v-for="(item, index) in statsForCompany(company)" 
                      v-bind:key="item.id()" 
                      :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'" 
                      class=" rc-text-value rc-text-centre"> {{ item.users() }} 
                  </div>
                </Row>
              </Box>
          </div>
         </Row>
        </Box>
       </Column>
      </Row> -->

      </Box>
     </Column>
    </Row>
    
    <SpacerRow />
    
  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

import CompanyStats from "@/domain/model/company/CompanyStats.js";
import CompanyStatsMap from "@/domain/model/company/CompanyStatsMap.js";
import CompanyStatsListFilter from "@/domain/model/company/CompanyStatsListFilter.js";
import FeatureMap from "@/domain/model/feature/FeatureMap.js";
import CompanyMap from "@/domain/model/company/CompanyMap.js";
import RcDomainMap from "@/domain/common/RcDomainMap.js";
//import Company from "@/domain/model/company/Company.js";
//import TimesheetMap from "@/domain/model/timesheet/TimesheetMap.js";

import SpacerRow from '@/components/row/SpacerRow.vue';
import ConstUtils  from '@/utils/ConstUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ContentUtils  from '@/utils/ContentUtils.js';

import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";
import TitleRowV2 from '@/portals/shared/library/title/TitleRow.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Button   from '@/portals/shared/library/button/Button.vue';
import Plotly from 'plotly.js-dist-min';

export default {
  name: 'portals-admin-stats',
  components: {
    SpacerRow, LoadingRow, TitleRowV2,
    Box, Row, Column, Button
  },
  props: {
    adminDisplay: { type: Boolean, default: true },
  },
  data() {
    return {
      chartData: {},
      up: 0,
      down: 0,
      startSub: false,
      isLoading: true,
      loadingMessage: null,
      isAdmin: false,
      MC: new MC(),
      Consts: ConstUtils,
      Strings: StringUtils,
      
      isLoadingMonthly: true,
      statsMap: {},
      statsMonthly:  new CompanyStats(this.domain),
      
      statsMonthlyMap: {},
      statsMonthlyNotPayingMap: {},
      
      statsMonthlyList: [],

      companyMap: {},
      companyList: [],
      
      companyNotPayingMap: {},
      companyNotPayingList: [],
    
      currentList: [],
      currentMap: {},
    };
  },
  computed: {
      ...mapGetters([
        'auth_connected',
        'auth_socket_status',
        'signin_event',
        'auth_client',
        'domain',
      ]),
      hasInspections: function() {
        return true;
      },
      hasTimesheets: function() {
        return true;
      },
      features: function() {
        var options = new FeatureMap(this.domain, {});
        if (!this.domain) {
          return options;
        }
        var session = this.domain.session();
        if (!session) {
          return options;
        }
        var company = session.company();
        if (!company) {
          return options;
        }
        options = company.features().find();
        return options
      },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
      ConnectionUtils.withAdminCompany(this);
      this.startSub = true;
      this.loadLast14Months();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    loadLast14Months: function() {
      if (!this.domain) {
        return;
      }
      this.isLoadingMonthly = true;
      var filter = new CompanyStatsListFilter();
      filter
        .withPagination(-1, 0)
        .withTypeMonthly()
        .done();
      var request = this.domain.events().company().statsList(filter);
      request.send(this.loadLast14MonthsListener);
    },
    
    loadLast14MonthsListener: function(event) {
      var payload = event.payload();
      this.up = 0;
      this.down = 0;
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.statsMonthlyMap = new RcDomainMap(this.domain, payload.valuesFor("MonthlyStatsPaying"), "MonthlyStatsPaying");
          this.companyMap = new CompanyMap(this.domain, payload.valuesFor("payingCompanys"));
          this.companyList = this.companyMap.sortByName();
          
          this.statsMonthlyNotPayingMap = new RcDomainMap(this.domain, payload.valuesFor("MonthlyStatsNotPaying"), "MonthlyStatsNotPaying");
          this.companyNotPayingMap = new CompanyMap(this.domain, payload.valuesFor("notPayingCompanys"));
          this.companyNotPayingList = this.companyNotPayingMap.sortByName();
          
          this.currentList = this.companyList;
          this.currentMap = this.statsMonthlyMap;
          this.setupCurrentList();
        }
      }
    },

    setupCurrentList() {
      for (let i = 0; i < this.currentList.length; i++) {
        let company = this.currentList[i];
        let companyData = {};
        companyData["name"] = ContentUtils.encode(company.name());
        companyData["x"] = this.previous15Months();
        companyData["type"] = "bar";
        let line = {
          width: 1,
        };
        let marker = {};
        companyData["line"] = line;
        companyData["marker"] = marker;
        let stats = this.statsForCompany(company);
        let data = [];
        let item = null;
        for (let j = 0; j < stats.length; j++) {
          item = stats[j];
          if (item && item.inspections) {
            data.push(item.inspections())
          } else {
            data.push(0);
          }
        }
        if (item != null) {
          if (item.get("diff") > 0) {
            line["color"] = 'green';
          }
          if (item.get("diff") < 0) {
            line["color"] = 'red';
          }
        }
        companyData["y"] = data;
        this.chartData[company.id()] = [companyData];
      }
      this.isLoadingMonthly = false;
      setTimeout(this.delayedRender, 1000);
    },
    
    delayedRender() {
      var layout = {
          height: 150,
          margin: {"t": 0, "b": 20, "l": 20, "r": 0},
        };
      for (let i = 0; i < this.currentList.length; i++) {
        let company = this.currentList[i];
        let data = this.chartData[company.id()];
        var elm = this.$refs[company.fileSystemId()][0];
        Plotly.newPlot(elm, data, layout);
      }
    },

    statsForCompany: function(company) {
      var statsMap = this.currentMap.get(company.id());
      var companyStats = new CompanyStatsMap(this.domain, statsMap);
      var list = companyStats.sortByCreatedDateDesc();
      return list;
    },
    
    previous15Months: function() {
      var months = [];
      if (!this.domain) {
        return months;
      }
      var now = this.domain.time().now();
      var month = now.month();
      for (var i = 0; i < 15; i++) {
        month = month.previous();
        months.push(month.displayAs_YYYY_MM_DD());
      }
      return months;
    },
    
    toggle: function(to) {
      if (to === "notPaying") {
        this.currentMap = this.statsMonthlyNotPayingMap;
        this.currentList = this.companyNotPayingList;
      } else {
        this.currentMap = this.statsMonthlyMap;
        this.currentList = this.companyList;
      }
      this.isLoadingMonthly = true;
      this.setupCurrentList();
    }
  },
}
</script>